
.btn {
    border:2px solid #007be4;
    border-radius:30px;
    padding:15px 30px;
    font-size:1.75em;
    color:#007be4;
    display: inline-block;
    &:hover {
    }
}
.btn_voir_tout {
  display: table;
margin: 30px auto;
  text-transform: uppercase;
  color: #4d4d4d;
  text-align: center;
  font-weight: 600;
  font-size: 1.8rem;
  padding: 20px 40px;
  transition: background .3s;
  border: 1px solid #4d4d4d;
	line-height: 1em;
  &:hover {
	background: #9d0019;
	border: 1px solid #9d0019;
	  color: #fff;
  }
}

.btn_form {
	margin: 15px auto 0;
  	color: $noir !important;
	font-weight: 600 !important;
  	background: none !important;
  	transition: background .3s;
	letter-spacing: 0.05em;
	padding: 20px 30px !important;
	border: 1px solid $noir !important;
	&:hover {
		background: $noir !important;
		border: 1px solid $noir !important;
		color: #fff !important;
	}
}

.lien_def{
	display:inline-block;
	color: $noir;
	font-size: 1.4rem;
	font-weight: 700;
	text-transform: uppercase;
	&::before{
		content: "\e901";
		font-family: 'icomoon';
		font-size: 1.5rem;
	}
}

.btn_filled {
    padding:15px 25px;
    border-radius:30px;
    background:#e67431;
    color:white;
    text-transform: uppercase;
    font-weight:500;
    transition:all 0.15s ease;
}
.btn_filled.blue {
    background:#204286;
}
.btn_filled:hover {
    color:white;
    background:#fda645;
}

.btn_empty {
    padding:18px 30px;
    border-radius:30px;
    background:transparent;
    border:3px solid #e67431;
    color:#e67431;
    text-transform: uppercase;
    font-weight:500;
    transition:all 0.15s ease;
}
.btn_empty:hover {
    background:#e67431;
    color:white;
}

.fermer{
	position:absolute;
	z-index: 5;
	top: 10px;
	right: 10px;
	&::before{
		content: '\e909';
		font-family: 'icomoon';
		font-size: 3.6em;
		font-weight: 600;
		color: $noir;
	}
	&:hover{
		&::before{
			color: $noir;
		}
	}
}