@media screen and (max-width:1860px){
    .deroulant{
        display: none;
    }
    .btn_menu{
        display: block !important;
    }
    .sub_menu ul{
        padding-right:0px !important;
    }
    .sub_menu li{
        float:left;
    }
    .sub_menu ul li{
        width:13% !important;
    }
    .sub_menu ul li:first-of-type{
        width:22% !important;
    }
    .sub_menu li:last-child:after{
        display: none;
    }
}

@media screen and (max-width:1600px){
    .content{
        padding:0 !important;
        .content_inner{
            padding:0 5% !important;
            img{
                max-width:100% !important;
            }
        }
    }
}

@media screen and (max-width:1500px){
    .sub_menu ul li:first-of-type{
        display: block !important;
        float:none !important;
        width:100% !important;
        height:auto !important;
        span{
            margin:0 20px !important;
        }
        h2 {
            margin:0 !important;
            br{
            display: none;
            }
        }
    }
    .sub_menu ul li{
        width:16.66666% !important;
    }
    .sub_menu ul li:nth-child(2):before{
        content:none !important;
    }
}

@media screen and (max-width:1400px){
    .features .slider{
        margin:0 7%;
        .carousel{
            width:100% !important;
        }
        .description{
            padding:30px !important;
            position: relative !important;
            width:auto !important;
            right:0 !important;
            strong{
                font-size:1em !important;
            }
        }
    }
}

@media screen and (max-width:1280px){
    body.home{
        .actualites{
            .actu{
                width:100% !important;
                display: block !important;
                float:none !important;
                clear:both !important;
                .image_holder{
                    max-height: 200px;
                    overflow: hidden;
                }
                .date{
                    width:auto;
                }
            }
        }
    }
    .catalogue{
        .description{
            margin:0 auto !important;
            padding:0 30px 50px !important;
            img{
                max-width:100%;
            }
        }
    }
    .actualites{
        .actu {
            width:48% !important;
        }
    }
    body.page .content .listing_content .listing_produit .row_produit .produit{
       width:31% !important;
    }
}

@media screen and (max-width:1160px){
    .content_inner{
        padding:20px !important;
        .title_inner{
            top: -33px !important;
        }
        .statut{
            top: -40px !important;
        }
        .right_content{
            .champ.half{
                label {
                    font-size:1.75em !important;
                }
                span.vente,
                span.arrow,
                span.commission{
                    font-size:4em !important;
                }
            }
        }
    }
    .highlight{
        .left,.right{
            width:100% !important;
            float:none!important;
            display: block !important;
        }
        p{
            text-align: center !important;
        }
    }
    .produit{
        width:24% !important;
    }
    .navigation{
        padding:70px 20px !important;
        .btn{
            font-size:0 !important;
            }
        span{
            font-size:1.5rem;
            margin:0 !important;
        }
    }
}

@media screen and (max-width:980px){
    body.page .content .listing_content .listing_produit .row_produit .produit{
       width:48% !important;
    }
}

@media screen and (max-width:960px){
    .content {
        .steps{
            .step{
                p {
                    padding: 0  !important;
                }
            }
        }
        .left.half,
        .right.half{
            font-size:0.9em;
        }
        .right.half {
            p{
                padding:0 !important;
            }
        }
    }
    .content_inner{
        padding:20px !important;
        .right_content{
            .champ.half{
                display: block !important;
                float:none !important;
                width:100% !important;
                span.vente,
                span.arrow,
                span.commission{
                    font-size:5em !important;
                }
                
            }
            
        }
    }
    .sub_menu{
        ul{
            li{
                width:33.333% !important;
            }
            li:nth-child(4):after{
                content:none !important;
            }
        }
    }  
    .produit{
        width:31% !important;
    }
    
}

@media screen and (max-width:768px){
        body{
            background-size: contain !important;
            background-position: 0px 320px !important;
            #top_barre{
                height:auto !important;
                img{
                    margin:0 auto;
                    display: block;
                    float:none;
                }
                #connexion{
                    float: none !important;
                    margin: 0 auto;
                    padding:50px 10px !important;
                    display: block !important;
                    text-align: center;
                }
            }
            #bandeau {
                iframe{
                    margin: 0 5%;
                    width: 90%;
                }
                #features {
                    .feature {
                        width:50% !important;
                        padding:20px;
                    }
                }
            }
            #boutiques{
                .boutique{
                    width:50% !important;
                }
            }
            #actualites{
                .actu{
                    padding: 0 18px !important;
                    img {
                        max-width: 150px !important;
                        max-height: 150px !important;
                    }
                    p {
                        font-size:1.75em !important;
                    }
                }
            }
            .left_menu{
                width:90% !important;
                display: block !important;
                float:none !important;
                margin:20px auto !important;
            }
            .right_content {
                width:90% !important;
                display: block !important;
                float:none !important;
                margin:20px auto !important;
            }
            .content_inner{
                span.produits{
                    display: table !important;
                    float:none !important;
                    margin:20px auto !important;
                    top:0 !important;
                }
                .champ {
                    .champ {
                    width:49% !important;
                    }
                }
            }
            .left.half,
            .right.half{
                width:100% !important;
                display: block !important;
                float:none !important;
            }
        }
    body.page .content_title{
        display: block !important;
    span{
        display: block !important;
        text-align: center !important;
        width:auto !important;
        margin-bottom:20px !important;
        strong{
            font-size:2em !important;
        }
    }
    .title{
        font-size:2em !important;
        padding: 15px !important;
        line-height:1 !important;
        z-index: 999 !important;
        }
    }
    .actualites{
        .actu {
            .image_holder{
                min-height:200px !important;
                overflow: hidden !important;
            }
            .date{
                width:auto !important;
            }
            width:100% !important;
        }
    }
    body.page .content .listing_content .listing_produit .row_produit .produit{
       width:98% !important;
    }
}

@media screen and (max-width:668px){
    header{
        padding:30px !important;
    }
    footer{
        padding:30px 20px !important;
    }
    .content{
        padding:0 !important;
        .image_holder{
            display: flex !important;
            flex: 1 0 0 !important;
            position: relative !important;
            min-height:386px !important;
            overflow: hidden !important;
            img{
                display: block !important;
                width: 100% !important;
                height: 100% !important;
                position: absolute !important;
                -o-object-fit: cover !important;
                object-fit: cover !important;
            }
        }
        .content_inner{
            padding:0 5% !important;
            display: block !important;
            img{
                max-width:100% !important;
                
            }
            .col{
                display: block !important;
                vertical-align: inherit !important;
                float:none !important;
                width:auto !important;
            }
        }
    }
    .activity{
        width:100% !important;
        p {
            padding:50px 25px !important;
        }
    }
    .recherche{
        float:none !important;
        text-align: center !important;
        width:100% !important;
        display: flex !important;
        align-items: center !important;
        position:relative !important;
        input{
            width:auto !important;
            max-width:300px !important;
            width:100% !important;
        }
    }
    header nav.menu{
        float:right !important;
    }
}

@media screen and (max-width:640px){
    body {
        #edito {
            p {
                display: block !important;
                float:none !important;
                width:100% !important;
            }
        }
        #edito{
            img{
                width:100% !important;
                margin: 0 auto !important;
                display: block !important;
                float:none !important;
            }
        }
        #actualites{
            .actu{
                padding: 0 15px !important;
                img {
                    max-width: 100px !important;
                    max-height: 100px !important;
                }
                p {
                    font-size:1.5em !important;
                }
            }
        }
        .title_page {
            padding-top:25px !important;
            h1 {
                font-size:5em !important;
            }
        }
        .content {
            margin:40px 0 !important;
            padding:20px !important;
            p {
                padding:0 25px;
            }
            .steps{
                margin:0 0 100px !important;
                .step{
                    margin:0 1% !important;
                    width:30% !important;
                    p {
                        font-size:1.5em !important;
                        margin:10px 0  !important;
                    }
                    &:after {
                        display: none !important;
                    }
                }
                span {
                    font-size:5em !important;
                }
            }
        }
        .content_inner{
            text-align: center;
            .title_inner {
                display: table !important;
                top: -33px !important;
                margin:-20px auto !important;
            }
            .title_inner + span,
            .silver + .gold,
            .gold + .platine {
                margin-top:20px !important;
            }
            .silver, .gold, .platine{
                top:0px !important;
            }
            h2 {
                text-align: center !important;
                &:after {
                    margin:15px auto !important;
                }
            }
            .champ{
                width:100% !important;
                display: block;
                position: relative;
                padding:0 !important;
                .champ{
                    padding: 10px 20px !important;
                    width:100% !important;
                }
                .btn_filled {
                    margin:30px auto !important;
                }
                .couleur {
                    margin:10px !important;
                }
            }
            .champ.social {
                span {
                    left:0;
                }
            }

        }
    }
    .sub_menu{
        ul{
            li{
                width:50% !important;
                padding:20px !important;
            }
            li:after{
                content:none !important;
            }
        }
    }  
    .produit{
        width:48% !important;
    }
}

@media screen and (max-width:550px){
    footer{
        .col{
            width:100% !important;
            float:none !important;
            text-align: center !important;
            .btn {
                display: table !important;
                margin:20px auto !important;
            }
        }
    }
}

@media screen and (max-width:480px){
    #bandeau {
        #features {
            .feature {
                width:100% !important;
                display: block;
                float:none;
            }
        }
    }
    #boutiques{
        .boutique{
            width:100% !important;
            display: block !important;
            
        }
    }
    #actualites{
        h2{
            float:none !important;
            text-align: center !important; 
            display: block !important;
            &:after {
                margin: 30px auto 50px !important;
            }
        }
        h2 + .btn_empty{
            float: none !important;
            margin: 30px auto !important;
            display: table !important;
        }
        .actu{
            width:100% !important;
            float:none !important;
            display: block !important;
             img {
                max-width: 175px !important;
                max-height: 175px !important;
            }
            p {
                font-size:2em !important;
            }
        }
    }
    .content_inner{
        padding:20px !important;
        .right_content{
            .champ.half{
                label {
                    font-size:1.75em !important;
                }
                span.vente,
                span.arrow,
                span.commission{
                    font-size:4em !important;
                }
            }
        }
 
    }
    .content {
        .step {
            hr {
                display: none !important;
            }
            p {
                display: none;
            }
        }
    }
    .sub_title{
        display: block !important;
        span{
            display: block !important;
            text-align: center !important;
        }
        h2 {
            display:block !important;
            text-align: center !important;
        }
    }
    .produit{
            width:98% !important;
        }
}