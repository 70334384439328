
/* CSS Document */

html{font-size:100%;font-size: 62.5%;}

body{margin:0;padding:0;line-height:normal;font-family: 'Roboto', sans-serif;font-weight: 400;color:#333333;font-size:1em;}

h1,h2,h3,h4,h5,h6{line-height:1.2;font-style:normal;font-weight:700;}

h1{font-size:5em;color:#204286;margin-top:20px;}

h2{font-size:2.4em; color: #204286;}

h3{font-size:2em; color:#204286;}

h4{font-size:1em}

ol,ul{margin:0;padding:0}

li{
	list-style: none;
}
p{margin:1.1em 0}

address{margin:.75em 0;font-style:normal}

a{text-decoration:none;color:#048C9D;cursor:pointer;}

a:hover{color: #e69932;}

a img{border:none}

em{font-style:italic}

fieldset,form{margin:0;padding:0;border:none}

button,input,select{vertical-align:middle}

.clear{clear:both}

.clearfix{clear:both}

.row {
    display:block;
    width:100%;
    position: relative;
}

.left {
    float:left;
}
.right {
    float:right;
}

.half {
    width:50%;
    display: inline-block;
}

img{
	height: auto;
}
article p{
	font-size: 1.5em;
}
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
}
.columns {
    -webkit-columns: 4;
    -moz-columns: 4;
    columns: 4;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    column-gap: 15px;
    -webkit-column-rule: 1px solid #FFFFFF;
    -moz-column-rule: 1px solid #FFFFFF;
    column-rule: 1px solid #FFFFFF;
}
.overall {
	margin: 0 auto;
	max-width: 1250px;
}
.overallmax{
	margin: 0 auto;
	max-width: 1920px;
}
.overallmin{
	margin: 0 auto;
	max-width: 1040px;
}
.decallage {
	margin: 0 -10px;
}