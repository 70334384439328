input[type="submit"], button{
	display: inline-block;
	  text-transform: uppercase;
	  color: #FFF;
	  text-align: center;
	  font-size: 1.8rem;
	  padding: 20px 25px;
	  background: #ed3f3d; /* Old browsers */
	  transition: background .3s;
	  border: none;
	font-weight: 500;
	font-family: 'Poppins', sans-serif;
	cursor: pointer;
		@extend %effet_hover;
	line-height: 1em;
}

/* retablissement de l'apparance des selects (surtout ios) */
input[type="submit"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

form span.select{
	display: block;
	background: url(../images/x2/puce_select.png);
	background-size: 35px;
	background-repeat: no-repeat;
	background-color: #ffffff;
	background-position: right center !important;
	margin: 15px 0;
	border-radius: 3px;
	padding: 5px;

	>select{
		display: block;
		width: 100%;
		background-color:transparent !important;
		border: none;
		height: 30px;
		cursor: pointer;
		-webkit-appearance: none!important;
		-moz-appearance: none!important;
		appearance: none!important;
		padding: 0 10px;
		color: $noir;
		font-size:1.6rem;
	}
}

input[type="text"], textarea, input[type="tel"], input[type="email"], input[type="password"], input[type="search"]{
	border-radius: 30px;
	border: 2px solid #1c3284;
    padding:10px 15px;
    font-size:1.5em;
    text-transform:uppercase;    
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #1c3284;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #1c3284;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #1c3284;
}
:-moz-placeholder { /* Firefox 18- */
  color: #1c3284;
}

input[type="radio"]{
	width: 24px !important;
	height: 24px !important;
	margin: 0 10px 0 0;
}

@media (max-width: $mobilep) {
	form span.select{
		margin: 5px 0;
	}
}