footer{
    background:#d5d5d5;
    padding: 40px 13%;
    .col{
        width:33%;
        display:inline-block;
        vertical-align: top;
        padding:0 15px;
        p{
            font-size:1.5em;
            display: block;
            margin:20px 0 0;
        }
        h3{
            margin:20px 0;
        }
        .btn{
            display: table;
            background:#007be4;
            color:white;
            font-size:1;
            font-weight:300;
            padding:10px 15px;
            margin-top:20px;
        }
    }
    .col:nth-child(2){
        padding:0;
        text-align: center;
        img{
            margin:0 auto;
            width:100%;
        }
    }
    .col:nth-child(3){
        text-align: right;
        a{
            font-size:1.5em;
            color:#444444;
            font-weight:bold;
        }
    }
}