@media (max-width:$desktopl) {
    body {
        
    }
}

body{
    .content{
        padding: 0 50px;
            .sub_title{
                color: #124e9d;
                display: inline-block;
                vertical-align: middle;
                margin-bottom:50px;
            span{
                margin-right: 20px;
                font-size: 6em;
                display: inline-block;    
                vertical-align: middle;
            }
            h2{
                font-size: 3em;
                display: inline-block;
                vertical-align: middle;
                text-align: left;
                text-transform: uppercase;
            }
        }
    }
}

body.page{
    .content_title {
        display: flex;
        margin-bottom:50px;
        position: relative;
        max-width:891px;
        span{
            width:225px;
            display: block;
            font-size:4em;
            text-align: right;
            color:#124e9d;
            padding:0 50px;
            strong{
                font-size: 3em;
                font-weight: 800;
                margin-bottom: 0;
                letter-spacing: -5px;
                margin: -30px 0;
                display: block;
            }
        }
        .image_holder{
            width:100%;
        }
        .title{
            background:rgba(18, 78, 157, 0.75);
            padding:30px;
            text-transform: uppercase;
            color:white;
            font-weight:bold;
            line-height:1;
            font-size:4em;
            position: absolute;
            bottom:0;
            left:0;
        }
    }
    .content{
        background:url(../images/pattern1.png) top left no-repeat;
        padding: 0 calc(50px + 7%);
        background-position:7% 100px;
        .sub_title {
            font-size:1.25em;
            h2{
                color:#124e9d;
            }
        }
        .content_inner{
            padding: 0 10%;
            display: flex;
            justify-content: space-between;    
            .navigation{
                display: block;
                
            }
            .col {
                width:46%;
                display: inline-block;
                vertical-align: middle;
                float:left;
            }
            p{
                font-weight:300;
                font-size:1.75em;
                line-height:1.5em;
                text-align: justify;
                color:#124e9d;
            }
           
        }
        .listing_content{
            display: flex;
            justify-content: flex-start;
            .categories{
                background:#124e9d;
                color:white;
                padding:30px 15px;
                display: table;
                h3{
                    color:white !important;
                    margin-bottom:50px;
                    margin-top:0;
                    padding:0 20px;
                }
                >ul{
                    font-size:1.6em;
                    >li{
                        > a {
                            padding:10px 15px;
                            color:white;
                            display: block;
                            &:hover{
                                background:white;
                                color:#124e9d;
                            }
                        }
                        >ul{
                            padding:10px;
                            background:#0f3d7b;
                            display: block;
                            >li{
                                a{
                                    color:white;
                                    display: block;
                                    padding:10px 15px;
                                    font-size:0.8em;
                                    &:hover{
                                        background:rgba(255,255,255,0.15);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .listing_produit{
                padding:0 50px;
                 .row_produit{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    h3{
                        display: flex;
                        width:100%;
                        white-space: pre;
                        align-items: center;
                        &:after{
                            content:'';
                            height: 1px;
                            width:100%;
                            background: #204286;
                            margin-left:50px;
                            display: inline-block;
                            vertical-align: middle;

                        }
                    }
                    .produit{
                        width:24%;
                        position:relative;
                        .image_holder{
                            display: flex;
                            flex: 1 0 0;
                            position: relative;
                            min-height:230px !important;
                            span{
                                background: rgba(18, 78, 157, 0.75);
                                color:white;
                                bottom:0;
                                position: absolute;
                                left:0;
                                right:0;
                                padding:10px 30px;
                                font-size:1.5em;
                                height:59px;
                                font-weight:300;
                                strong{
                                    float:right;
                                    font-size:2em;
                                }
                            }
                            img{
                                display: block;
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                -o-object-fit: cover;
                                object-fit: cover;
                            }
                        }
                        .description{
                            background:white;
                            padding:30px;
                            .qte{
                                span{
                                    vertical-align: middle;
                                    font-size:1.5em;
                                    color:#204286;
                                    font-weight:bold;
                                    display: inline-block;
                                    line-height:40px;
                                }
                                input[type="number"]{
                                    float:right;
                                    height:43px;
                                    border:1px solid #204286;
                                    display: inline-block;
                                    vertical-align: middle;
                                    font-size:1.5em;
                                    max-width:50px;
                                    text-align:center;
                                    font-weight:bold;
                                }
                            }
                            p{
                                color:#333333;
                                font-size:1.5em;
                                line-height:1.2em;
                                strong{
                                    font-weight:bold;
                                }
                            }
                            a{
                                font-size:1.75em;
                                margin-top:30px;
                                display: block;
                                text-align:center;
                                color:#204286;
                                font-weight:bold;
                                span{
                                    font-size:1.5em;
                                    vertical-align: middle;
                                    display: inline-block;
                                    margin-right:30px;
                                }
                            }
                        }
                        &:after{
                            content:'';
                            border:0px solid rgba(18, 78, 157, 0.10);
                            position: absolute;
                            z-index:9;
                            top:0;
                            right:0;
                            bottom:0;
                            left:0;
                            transition:all 0.2s ease-out;
                            display: block;
                            pointer-events: none;
                        }
                        &:hover:after{
                            border:15px solid rgba(18, 78, 157, 0.10);
                        }
                    }

                }
            }
        }
        .navigation{
            padding:70px 10%;
            text-align: center;
            .btn{
                margin:0 5px;
                span{
                    margin-left:15px;
                }
            }
            .btn.facebook{
                background:#124e9d;
                color:white;
                border-color:#124e9d;
                span{
                    color:white;
                }
            }
            .btn.twitter{
                background:#5dc9f9;
                color:white;
                border-color:#5dc9f9;
                span{
                    color:white;
                }
            }
            .btn:first-of-type{
                float:left;
                span{
                    margin-right:15px;
                }
            }
            .btn:last-of-type{
                float:right;
                span{
                    margin-right:15px;
                }
            }
        }
        .row_activity {
             display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            
            .activity{
                width:48%;
                position:relative;
                margin-bottom:100px;
                
                .image_holder{
                    width:600px;
                    max-width:100%;
                    overflow: hidden;
                    float:right;
                    display: flex;
                    flex: 1 0 0;
                    position: relative;
                    min-height:404px;
                    img{
                        display: block;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        -o-object-fit: cover;
                        object-fit: cover;
                        
                    }
                }
                p {
                    background:white;
                    padding:75px 50px;
                    font-size:1.75em;
                    text-align: justify;
                    color:#124e9d;
                    font-weight:300;
                    line-height:1.5em;
                    max-width:430px;
                    position: absolute;
                    top:100px;
                    z-index: 9;
                }
                span{
                    background: rgba(18, 78, 157, 0.75);
                    color:white;
                    padding:20px 50px;
                    text-transform: uppercase;
                    font-size:3em;
                    font-weight:bold;
                    position: absolute;
                    top:75px;
                    left:0;
                    z-index: 9;
                }
            }
            
        }
    }
}

.ariane{
    font-size: 1.6em;
    font-weight: 400;
    background: #f3f6fa;
    margin: 50px;
    padding: 25px;
    border-top: 1px solid #114e9d;
    margin: 0px 50px 50px;
    .overall{
        padding:15px 75px;
    }
    span:last-of-type:after{
        content:none;
    }
    span:after{
        content:">";
        display: inline-block;
        color:#007be4;
        margin:0 10px;
    }
    span {
        color:#204286;
    }
    span.actif{
        font-weight:bold;
    }
}

#bandeau {
    height:829px;
    display: block;
    background:#0a52a0;
}
body.home{
    .sub_menu{
        .sub_title {
            margin-bottom:0;
        }
    }
}

.sub_menu{
    background:#e8f1fa;
    ul {
        vertical-align: middle;
        text-align: right;
        padding-right:10%;
        li{
            height:120px;
            width:12%;
            background:#124e9d;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            color:white;
            position: relative;
            padding:20px 20px 20px 60px;
            transition:all 0.3s ease;
            cursor:pointer;
            &:after{
                content: '';
                width: 47px;
                position: absolute;
                top: 0;
                bottom: 0;
                right: -25px;
                background: #114e9d;
                transform: skewX(20deg);
                transition:all 0.3s ease;
                z-index: 99;
            }
            span{
                font-size:5em;
                position: relative;
                z-index: 999;
                transition:all 0.3s ease;
            }
            span.icon-train, span.icon-voiture {
                font-size:4em;
            }
            span.icon-train + p, span.icon-voiture + p{
                margin-top:19px;
            }
            p{
                font-size:1.75em;
                margin-top:10px;
                font-weight:300;
                position: relative;
                z-index: 999;
            }
            &:hover{
                span{
                    top:-5px;
                    z-index: 99;
                }
            }
        }
        li.aeronautique{
            &:hover,&:hover:before,&:hover:after{
               background:#5c94c8; 
            }
        }
        li.train{
            &:hover,&:hover:before,&:hover:after{
               background:#b3b0ab;
                
            }
        }
        li.voiture{
            &:hover,&:hover:before,&:hover:after{
               background:#1f2825;
                
            }
        }
        li.pills{
            &:hover,&:hover:before,&:hover:after{
               background:#af0c3e;
                
            }
        }
        li.diamond{
            &:hover,&:hover:before,&:hover:after{
               background:#379591;
                
            }
        }
        li.gears{
            &:hover,&:hover:before,&:hover:after{
               background:#fdc52e;
                
            }
        }
        li:nth-child(2){
             &:before{
                content: '';
                width: 47px;
                position: absolute;
                top: 0;
                bottom: 0;
                left: -25px;
                background: #114e9d;
                transform: skewX(20deg);
                transition:all 0.3s ease;
            }
        }
        li:first-of-type{
            width:27%;
            background:#f3faff;
            padding:20px 0;
            &:after{
                content:none;
            }
            span,h2{
                color:#124e9d;
                display: inline-block;
                vertical-align: middle;
                text-align: left;
                text-transform: uppercase;
            }
            span{
                margin-right: 20px;
                font-size: 6em;
                margin-top: -10px;
            }
            h2{
                font-size: 3em;
                margin-top: 10px;
            }
        }
    }
}

.features{
    box-sizing:border-box;
    display: block;
    padding:94px 0;
    background:url(../images/pattern1.png) no-repeat #f3faff;
    background-position: 50px 0px;
    clear:both;
    .slider{
        max-width:100%;
        margin-left:7%;
        display: block;
        position: relative;
        .carousel{
            width:70%;
        }
        .owl-item {
            width:100%;
            max-height:554px;
            overflow: hidden;
        }
        .overall_slide{
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            .num{
                position: absolute;
                top:0;
                left:0;
                bottom:0;
                background:rgba(18, 78, 157, 0.75);
                width:269px;
                padding:0px 30px;
                span{
                    font-size:15em;
                    color:white;
                    font-weight:800;
                    letter-spacing: -10px;
                }
            }
        }
        .description{
            background: white;
            width: 30%;
            padding: 50px;
            font-size: 1.75em;
            line-height: 1.5em;
            position: absolute;
            z-index: 9999;
            right: 100px;
            bottom: 0;
            color:#124e9d;
            strong{
                text-transform: uppercase;
                font-weight:bold;
                color:#124e9d;
                display: block;
                margin-bottom:30px;
                font-size:2em;
                line-height:1.1em;
            }
        }
        .owl-controls{
            position: absolute;
            bottom:0;
            left:269px;
            .owl-prev{
                height:80px;
                width:80px;
                text-indent:-9999px;
                background:url(../images/prev.jpg);
                display: inline-block;
                vertical-align: middle;
            }
            .owl-next{
                height:80px;
                width:80px;
                text-indent:-9999px;
                background:url(../images/next.jpg);
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
}

.highlight{
    background: url(../images/pattern3.png) no-repeat #f3faff;
    background-position:15% -25px;
    padding: 0 7% 50px;
    display: block;
    .left,.right{
        width:50%;
    }
    .left{
    }
    p{
        text-align: right;
        margin:0 auto;
        strong{
            text-transform: uppercase;
            font-weight:bold;
            color:#124e9d;
            display: block;
            margin-bottom:30px;
            font-size:2em;
            line-height:1.1em;
        }
        max-width: 450px;
        padding: 50px;
        font-size: 1.75em;
        line-height: 1.5em;
        z-index: 9999;
        color:#124e9d;;
    }
    iframe{
        max-width:100%;
    }
}

.actualites{
    padding:50px 0;
    background: url(../images/pattern4.png) right  no-repeat;
    .sub_title{
            color: #124e9d;
            display: inline-block;
            vertical-align: middle;
            margin-bottom:50px;
        span{
            margin-right: 20px;
            font-size: 6em;
            display: inline-block;
            vertical-align: middle;
        }
        h2{
            font-size: 3em;
            
            display: inline-block;
            vertical-align: middle;
            text-align: left;
            text-transform: uppercase;
        }
    }
    .btn{
        display: inline-block;
        vertical-align: middle;
        float:right;    
    }
    .actu_wrapper{
        display: flex;
        justify-content:space-between;
        flex-wrap:wrap;
        .actu{
            position: relative;
            width:31%;
            display: inline-block;
            vertical-align: middle;
            margin:30px 0;
            .image_holder{
                width:100%;
                display: grid;
                img{
                    width:100%;
                }
            }
            .date{
                width:28%;
                position: absolute;
                top:0;
                right:0;
                bottom:0;
                min-width:122px;
                padding:10px 30px;
                background:rgba(18, 78, 157, 0.75);
                text-align: center;
                strong{
                    font-size: 7em;
                    color: white;
                    display: block;
                    font-weight: 800;
                    margin-bottom: -16px;
                }
                span{
                    color:white;
                    font-size:3em;
                    font-weight:300;
                }
            }
            .description{
                padding:30px;
                min-height:174px;
                width:72%;
                display: block;
                clear:both;
                background:white;
                position: relative;
                transition:all 0.3s ease;
                h3{
                    margin-top:0;
                    text-transform: uppercase;
                    color:#007be4;
                }
                p{
                    font-size:1.75em;
                    margin-bottom:0;
                }
            }
            &:hover{
                .description{
                    width:100%;
                }
            }

        }
    }
}

.catalogue{
    padding:0 7%;
    .image_holder{
        position:relative;
        img{
            max-width:100%;
        }
        &:after{
            content:"";
            position:absolute;
            left:0;
            bottom:-50px;
            right:0;
            height:100px;
            background: rgba(18, 78, 157, 0.75);
        }
    }
    .btn{
        background:#007be4;
        color:white;
        display: table;
        margin:0 auto;
    }
    .description{
        padding:0 75px 50px;
        max-width:815px;
        margin:-350px auto 0;
        display: table;
        text-align: center;
        background:white;
        position:relative;
        h2{
            text-transform: uppercase;
            font-size:3.5em;
        }
        p{
            font-size:1.75em;
            color:#124e9d;
            line-height:1.5em;
            margin:50px 0;
        }
    }
}

@media screen and (max-width:1200px){

}

@media screen and (max-width:1024px){

}

@media screen and (max-width:748px){

}
@media screen and (max-width:980px){

}

@media screen and (max-width:650px){

}

@media screen and (max-width:460px){

}

@media screen and (max-width:360px){

}



