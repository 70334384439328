
.clearfix {
    clear:both;
}


.logo_show {
    display: none !important;
}

.deroulant.actif .logo_show {
    display: block !important;
}

header{
    padding:15px 50px;
    .logo, nav, .recherche{
        display: inline-block;
        vertical-align: middle;
    }
    .recherche{
        float: right;
    }
    &:after{
        content:'';
        display: block;
        clear: both;
    }
    .logo{
        margin-right:30px;
    }
    nav.menu{
        position: relative;
        ul{
            li{
                display: inline-block;
                vertical-align: middle;
                margin:0 10px;
                position: relative;
                a{
                    font-size:1.85em;
                    color:#124e9d;
                    text-transform: uppercase;
                    white-space:pre;
                    padding:15px 20px;
                    border-radius:30px;
                    display: block;
                    &:hover{
                        background:#124e9d;
                        color:white;
                        
                    }
                }
                &:hover a{
                }
                /*&:after{
                    content:'';
                    position: absolute;
                    bottom:0;
                    left:50%;
                    right:50%;
                    height:2px;
                    background:#124e9d;
                    transition:all 0.3s ease;
                }*/
                &:hover:after{
                    left:0;
                    right:0;
                }
            }
            li.actif a{
            }
        }
    }
    .recherche{
        margin-top: 30px;
        input{
            height:42px;
            border:2px solid #1c3284;
            font-size:1.2em;
            width:300px;
            color:#1c3284;;
        }
        span{
            display: inline-block;
            font-size: 3em;
            vertical-align: middle;
            color: #1c3284;
            margin-left: 15px;
        }
    }
}
/*BURGER*/
	.picto_menu::before {
		content: "";
		max-width: 35px;
		height: 4px;
		display: block;
		margin-bottom: 4px;
		background: #0a52a0;
		transition: all 300ms;
	}
	.picto_menu::after {
		content: "";
		max-width: 35px;
		height: 4px;
		display: block;
		margin-bottom: 4px;
		background: #0a52a0;
		transition: all 300ms;
	}
	.picto_menu span {
		width: 24px;
		height: 4px;
		display: block;
		margin-bottom: 4px;
		background: #0a52a0;
		transition: all 300ms;
	}
	.picto_menu {
		float: left;
	}
	.btn_menu {
		color: #0a52a0;
		font-weight: 400;
		font-size: 1.5em !important;
		line-height: 1em;
		padding: 18px 30px;
        float: left;
		cursor: pointer;
		display: none;
		text-transform: uppercase;
	}
	/*FIN_BURGER*/
	.deroulant{
		list-style: none;
        z-index: 999;
        > li{
            float: left;
            position:relative;
            display: inline-block;
            a{
                color: white;
                display: block;
                line-height: 1em;
                text-transform: uppercase;
                transition: background 300ms;
                img{
                }
            }
            >ul{
                display: none;
                position: absolute;
                z-index: 3;
                background: #006ab1;
                margin: 0;
                list-style: none;
                max-height:0em;
                overflow: hidden;
                transition: 1s max-height 0.3s;
                -webkit-transition: 1s max-height 0.3s;
                -moz-transition: 1s max-height 0.3s;
                -o-transition: 1s max-height 0.3s;
                -ms-transition: 1s max-height none;
                >li {
                    float: left;
            		width: 100%;
                    a {
                        color: #FFF;
                        display: block;
                        background: #DDDDDD;
                        text-transform: inherit;
                        
                    }
                }
            }
            .recherche {
                span {
                }
            }
        }
       
	}
    .deroulant.actif{
        display: block;
        position: absolute;
        background:white;
        padding:20px;
        top: 55px;
        li{
            display: block;
            float:none;
            a{
                padding:15px 10px;
            }
        }
    }
	.deroulant > li:hover ul {
		display: block;
	}
	.deroulant>li>ul>li a:hover {
		background: #0785c5;
		border-bottom: 1px solid #0785c5;
	}