/* Scss Document */

$noir: #000000;

$desktopl: '1900px';
$desktop: '1200px';
$tabletp: '1024px';
$tablet: '980px';
$mobilep: '640px';
$mobile: '460px';

%effet_hover{
	transition: background 0.3s;
	&:hover{
	}
}
%voir_tout{
	color:$noir;
	font-size: 3.6em;
	line-height: 0.9em;
	display: inline-block;
	&:hover{
		color:$noir;
		&::before{
			color:$noir;
		}
	}
	&::before{
		content: '\e905';
		display: inline-block;
		color:$noir;
		font-family: 'icomoon';
		font-weight: 700;
		vertical-align: middle;
	}
}